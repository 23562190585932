<template>
  <v-row no-gutters justify="center">
    <v-col cols="6">
      <form_project/>
    </v-col>
    <v-col cols="10">
      <table_users/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    form_project: () => import("../site/modules/core/project-form-projectinfo"),
    table_users: () => import("../site/modules/core/project-table_users"),
  }
}
</script>
